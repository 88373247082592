import service from './service'

class AuthService {
    entity = 'auth'

    login (data) {
        return service.post(`/${this.entity}`, data)
    }

    checkAuth () {
        if (!localStorage.getItem('token')) return false;
        return service.get(`/${this.entity}`)
    }

    forgotPassword (data) {
        return service.post(`/${this.entity}/reset-password`, data)
    }

    verifyResetToken (token) {
        return service.get(`/${this.entity}/reset-password/${token}`)
    }

    resetPassword (data) {
        return service.post(`/${this.entity}/reset-password-token`, data)
    }

}

export default new AuthService()